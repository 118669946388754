<template>
    <styled-card
        id="no-marketplace-data"
        class="flex ma-3 xs12">
        <template #heading>
            Advertising Data Status
        </template>
        <template #action-buttons>
            <action-button
                icon="circle"
                color="red">
                Status: Missing Data
            </action-button>
        </template>
        <p class="message">
            There's no advertising data for this time range. Possible culprits could be:
        </p>
        <table class="culprits">
            <thead>
                <tr>
                    <th>Issue</th>
                    <th />
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>• Your {{ channelName }} account is still in the process of being set up</td>
                    <td />
                </tr>
                <tr>
                    <td>• There was an error retrieving stats from {{ channelName }}</td>
                    <td />
                </tr>
                <tr>
                    <td>• There were no running campaigns during this time period</td>
                    <td />
                </tr>
                <tr>
                    <td>• There was a billing issue or a spend cap was met</td>
                    <td />
                </tr>
            </tbody>
        </table>
    </styled-card>
</template>

<script>
import StyledCard from '@/components/globals/StyledCard';
import ActionButton from '@/components/globals/ActionButton';

export default {
    name: 'NoMarketplaceData',
    components: {
        StyledCard,
        ActionButton
    },
    props: {
        channelName: {
            type: String,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped>
.message {
    display: inline-block;
    margin: 20px;
    padding: 2px 10px;
    border-radius: 5px;
    font-weight: 600;
    color: $white;
    background: $carnation;
    text-transform: uppercase;
}
.culprits {
    width: 100%;
    border-collapse: collapse;
    border-bottom: 1px solid darken($gray-light, 10%);
    margin-bottom: 30px;
    thead {
        border-bottom: 1px solid darken($gray-light, 10%);
    }
    th {
        padding: 5px 60px;
        text-align: left;
        font-weight: 400;
        font-size: 12px;
        color: darken($gray-light, 30%);
    }
    td {
        padding: 10px 60px;
    }
}
</style>